// import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next'


function HarmonogramComponent(props) {
  const {t} = useTranslation('translation')

    return (
        // <section className="content">
        //     <div className="error-page">
        //         <h2 className="headline text-warning"> 404</h2>
        //         <div className="error-content">
        //             <h3><i className="fas fa-exclamation-triangle text-warning" /> Oops! Page not found. </h3>
        //             <p>
        //                 We could not find the page you were looking for.
        //                 Meanwhile, you may <Link to={`/`}>Dashboard</Link>.
        //             </p>
        //         </div>
        //     </div>
        // </section>

        <div>



<section id="harmonogram" className="hg_section pt-80 pb-80">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 col-md-12">
        {/* Title element */}
        <div className="kl-title-block text-center">
          {/* Title with custom font size and thin style */}
          <h3 className="tbk__title fs-xxl fw-thin">
            <span className="fw-semibold fs-xxxl fs-xs-xl tcolor">
              Harmonogram</span> tréningov
          </h3>
          {/* <div style={{height: 50}} /> */}
        </div>
      </div>
      <div className="col-sm-12 col-md-12">
        <div className="pricing-table-element" data-columns={5}>

        <div className="plan-column">
            <ul>
              <li className="plan-title">
                <div className="inner-cell">
                  Pondelok
                </div>
              </li>
              {props.category.includes("adults-beginers") &&
                <li className="subscription-price" style={{backgroundColor: "#9ecbff"}}>
                  <div className="inner-cell">
                    <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Gracie Combatives<sup>®</sup><br />Začiatočnícky program<br /><small>(13-99 rokov)</small></strong></div>
                    <span className="currency">07:00 - 08:00</span>
                  </div>
                </li>
              }
              {props.category.includes("adults-beginers") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
              {props.category.includes("kids") &&
                <li className="subscription-price" style={{backgroundColor: "#fff99e"}}>
                  <div className="inner-cell">
                    <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Gracie Bullyproof<sup>®</sup><br />Malí šampióni<br /><small>(5-8 rokov)</small></strong></div>
                    <span className="currency">16:00 - 16:50</span>
                  </div>
                </li>
              }
              {props.category.includes("kids") &&              
                <li className="subscription-price" style={{backgroundColor: "#ffd99e"}}>
                  <div className="inner-cell">
                    <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Gracie Bullyproof<sup>®</sup><br />Mladí zápasníci<br /><small>(8-12 rokov)</small></strong></div>
                    <span className="currency">17:00 - 18:00</span>
                  </div>
                </li>
              }
              {props.category.includes("adults-beginers") &&
                <li className="subscription-price" style={{backgroundColor: "#9ecbff"}}>
                  <div className="inner-cell">
                    <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Gracie Combatives<sup>®</sup><br />Začiatočnícky program<br /><small>(13-99 rokov)</small></strong></div>
                    <span className="currency">18:00 - 19:00</span>
                  </div>
                </li>
              }              
              {props.category.includes("adults-pro") &&
                <li className="subscription-price" style={{backgroundColor: "#f29eff"}}>
                  <div className="inner-cell">
                    <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Master Cycle<sup>®</sup><br />Program pre pokročilých</strong></div>
                    <span className="currency">19:00 - 20:00</span>
                  </div>
                </li>
              }
              {props.category.includes("adults-pro") &&

                <li className="subscription-price" style={{backgroundColor: "#f29eff"}}>
                  <div className="inner-cell">
                    <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Drilovanie techník pre pokročilých</strong></div>
                    <span className="currency">20:00 - 20:30</span>
                  </div>
                </li>
              }
            </ul>
          </div>

          <div className="plan-column">
            <ul>
              <li className="plan-title">
                <div className="inner-cell">
                  Utorok
                </div>
              </li>
              {props.category.includes("adults-beginers") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
              {props.category.includes("adults-beginers") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
              {props.category.includes("kids") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
              {props.category.includes("kids") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
              {props.category.includes("we") &&
              <li className="subscription-price" style={{backgroundColor: "#FFBCD9"}}>
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Women Empowered<sup>®</sup><br />{t('page.womenempowered')}<br /><small>(13-99 rokov)</small></strong></div>
                  <span className="currency">18:00 - 19:00</span>
                </div>
              </li>
              }
              {props.category.includes("adults-beginers") &&
              <li className="subscription-price" style={{backgroundColor: "#9ecbff"}}>
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Gracie Combatives<sup>®</sup><br />Začiatočnícky program<br /><small>(13-99 rokov)</small></strong></div>
                  <span className="currency">19:00 - 20:00</span>
                </div>
              </li>
              }
              {props.category.includes("adults-pro") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
            </ul>
          </div>

          <div className="plan-column">
            <ul>
              <li className="plan-title">
                <div className="inner-cell">
                  Streda
                </div>
              </li>
              {props.category.includes("adults-beginers") &&
              <li className="subscription-price" style={{backgroundColor: "#9ecbff"}}>
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Gracie Combatives<sup>®</sup><br />Začiatočnícky program<br /><small>(13-99 rokov)</small></strong></div>
                  <span className="currency">07:00 - 08:00</span>
                </div>
              </li>
              }
              {props.category.includes("adults-beginers") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
              {props.category.includes("kids") &&
              <li className="subscription-price" style={{backgroundColor: "#fff99e"}}>
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Gracie Bullyproof<sup>®</sup><br />Malí šampióni<br /><small>(5-8 rokov)</small></strong></div>
                  <span className="currency">16:00 - 16:50</span>
                </div>
              </li>
              }
              {props.category.includes("kids") &&
              <li className="subscription-price" style={{backgroundColor: "#ffd99e"}}>
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Gracie Bullyproof<sup>®</sup><br />Mladí zápasníci<br /><small>(8-12 rokov)</small></strong></div>
                  <span className="currency">17:00 - 18:00</span>
                </div>
              </li>
              }
              {props.category.includes("adults-beginers") &&
              <li className="subscription-price" style={{backgroundColor: "#9effac"}}>
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Gracie Combatives<sup>®</sup><br />Reflex development<br /><small>(13-99 rokov)</small></strong></div>
                  <span className="currency">18:00 - 19:00</span>
                </div>
              </li>
              }
              {props.category.includes("adults-pro") &&
              <li className="subscription-price" style={{backgroundColor: "#f29eff"}}>
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Master Cycle<sup>®</sup><br />Program pre pokročilých</strong></div>
                  <span className="currency">19:00 - 20:00</span>
                </div>
              </li>
              }
              {props.category.includes("adults-pro") &&
              <li className="subscription-price" style={{backgroundColor: "#f29eff"}}>
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Drilovanie techník pre pokročilých</strong></div>
                  <span className="currency">20:00 - 20:30</span>
                </div>
              </li>
              }
              </ul>
          </div>

          <div className="plan-column">
            <ul>
              <li className="plan-title">
                <div className="inner-cell">
                  Štvrtok
                </div>
              </li>
              {props.category.includes("adults-beginers") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
              {props.category.includes("adults-beginers") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
              {props.category.includes("kids") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
              {props.category.includes("kids") &&
              <li className="subscription-price" style={{backgroundColor: "#FFFF00"}}>
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Gracie Bullyproof<sup>®</sup><br />Jiu jitsu plyšáci<br /><small>(3-5 rokov)</small></strong></div>
                  <span className="currency">17:00 - 17:30</span>
                </div>
              </li>
              }
              {props.category.includes("adults-beginers") &&
              <li className="subscription-price" style={{backgroundColor: "#9ecbff"}}>
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Gracie Combatives<sup>®</sup><br />Začiatočnícky program<br /><small>(13-99 rokov)</small></strong></div>
                  <span className="currency">18:00 - 19:00</span>
                </div>
              </li>
              }
              {props.category.includes("adults-pro") &&
              <li className="subscription-price" style={{backgroundColor: "#f29eff"}}>
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Master Cycle<sup>®</sup><br />Program pre pokročilých</strong></div>
                  <span className="currency">19:00 - 20:00</span>
                </div>
              </li>
              }
              {props.category.includes("adults-pro") &&
              <li className="subscription-price" style={{backgroundColor: "#f29eff"}}>
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Drilovanie techník pre pokročilých</strong></div>
                  <span className="currency">20:00 - 20:30</span>
                </div>
              </li>
              }
            </ul>
          </div>

          <div className="plan-column">
            <ul>
              <li className="plan-title">
                <div className="inner-cell">
                  Piatok
                </div>
              </li>
              {props.category.includes("adults-beginers") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
              {props.category.includes("adults-beginers") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
              {props.category.includes("kids") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
              {props.category.includes("kids") &&
              <li className="subscription-price" style={{backgroundColor: "#FFA500"}}>
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Gracie Bullyproof<sup>®</sup><br />Black Belt Club<br /><small>(iba na pozvánku)</small></strong></div>
                  <span className="currency">17:00 - 18:00</span>
                </div>
              </li>
              }
              {props.category.includes("we") &&
              <li className="subscription-price" style={{backgroundColor: "#FFBCD9"}}>
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong>Women Empowered<sup>®</sup><br />{t('page.womenempowered')}<br /><small>(13-99 rokov)</small></strong></div>
                  <span className="currency">18:00 - 19:00</span>
                </div>
              </li>
              }
              {props.category.includes("adults-pro") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
              {props.category.includes("adults-pro") &&
              <li className="subscription-price">
                <div className="inner-cell">
                  <div style={{padding: "15px", fontSize: "110%"}} className="black"><strong></strong></div>
                  <span className="currency"></span>
                </div>
              </li>
              }
            </ul>
          </div>


        </div>
        {/*/ Pricing table element with 4 columns */}
      </div>
      {/*/ col-sm-12 col-md-12 */}
    </div>
    {/*/ row */}
  </div>
  {/*/ container */}
</section>





        </div>
    )
}
export default HarmonogramComponent
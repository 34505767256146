import React from "react"
import PageHeader from "../components/Header"
// import UserDetail from "../components/UserDetail"
// import PageSlider from "../components/Slider"
// import WelcomePage from "../components/WelcomePage"
// import Selector from "../components/Selector"
// import FreeTrial from "../components/FreeTrial"
import PageFooter from "../components/Footer"
// import { useTranslation } from 'react-i18next'


const Gdpr = () => {
    // const {t} = useTranslation('translation')

    // const dispatch = useDispatch()
    // const { userProfile } = useSelector((state) => state)
    // const debug = JSON.stringify(userProfile, null, 2)

    // const [hasRoles, setHasRoles] = useState(false)
    // const [hasAwards, setHasAwards] = useState(false)
    // const [hasAdultRanks, setHasAdultRanks] = useState(false)
    
    // useEffect(() => {
    //     if (userProfile.roles) { setHasRoles(true) }
    //     if (userProfile.awards) { setHasAwards(true) }
    //     if (userProfile.adultRanks) { setHasAdultRanks(true) }
    // }, [userProfile])
    

    return (
        <div>
            <PageHeader />


            <div id="page_header" className="page-subheader site-subheader-cst">
                <div className="bgback" />
                <div className="th-sparkles" />
                <div className="kl-bg-source">
                    <div className="kl-bg-source__bgimage" style={{backgroundImage: 'url(images/general-bg-pc.jpg)', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPositionX: 'center', backgroundPositionY: 'center', backgroundSize: 'cover'}} />
                    <div className="kl-bg-source__overlay" style={{backgroundColor: 'rgba(53,53,53,0.1)'}} />
                </div>


                <div className="ph-content-wrap d-flex">
                <div className="container align-self-center">
                    <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="clearfix" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6" style={{backgroundColor: 'rgba(0,0,0,0.5)', padding: 15,}}>
                        <div className="subheader-titles">
                        <h2 className="subheader-maintitle">GDPR</h2>
                        <h4 className="subheader-subtitle">Zásady spracúvania osobných údajov</h4>
                        </div>
                    </div>
                    </div>
                </div>
                </div>


                {/* <div className="kl-bottommask kl-bottommask--mask3">
                
                <svg width="2700px" height="57px" className="svgmask" viewBox="0 0 2700 57" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-mask3">
                    <feOffset dx={0} dy={3} in="SourceAlpha" result="shadowOffsetInner1" />
                    <feGaussianBlur stdDeviation={2} in="shadowOffsetInner1" result="shadowBlurInner1" />
                    <feComposite in="shadowBlurInner1" in2="SourceAlpha" operator="arithmetic" k2={-1} k3={1} result="shadowInnerInner1" />
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0" in="shadowInnerInner1" type="matrix" result="shadowMatrixInner1" />
                    <feMerge>
                        <feMergeNode in="SourceGraphic" />
                        <feMergeNode in="shadowMatrixInner1" />
                    </feMerge>
                    </filter>
                </defs>
                <path d="M-2,57 L-2,34.007 L1268,34.007 L1284,34.007 C1284,34.007 1291.89,33.258 1298,31.024 C1304.11,28.79 1329,11 1329,11 L1342,2 C1342,2 1345.121,-0.038 1350,-1.64313008e-14 C1355.267,-0.03 1358,2 1358,2 L1371,11 C1371,11 1395.89,28.79 1402,31.024 C1408.11,33.258 1416,34.007 1416,34.007 L1432,34.007 L2702,34.007 L2702,57 L1350,57 L-2,57 Z" className="bmask-bgfill" filter="url(#filter-mask3)" fill="#fbfbfb" />
                </svg>

              <i className="fas fa-angle-down" />
            </div> */}
            
            </div>




            <section id="services" className="hg_section pt-50 pb-100">
            <div className="container">

                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-xxl tcolor fw-bold">
                    ZASLANÍM PRIHLÁŠKY UDEĽUJEM SÚHLAS, V SÚLADE S PLATNÝM ZÁKONOM O OCHRANE OSOBNÝCH ÚDAJOV PRE OZ BJJ Levice
                    </h3>

                    
<h4 className="tbk__icon fs-xl tcolor">Občianke združenie</h4>
<p><strong>BJJ Levice</strong>, Holubyho 5, 934 01 Levice, IČO: : 55 261 221<br />
<strong>Poverené osoby:</strong> Boris Mikuška, Janka Mikušková, Ján Furi<br />
<strong>Emailová adresa:</strong> info@bjjlevice.sk
</p>

<p>Vaše osobné údaje spracúvame v rámci plnenia povinností OZ BJJ Levice. ako prevádzkovateľa občianskeho združenia (ďalej len „Prevádzkovateľ“). Pri spracúvaní osobných údajov Prevádzkovateľom ste dotknutou osobou, t. j. osobou, o ktorej sú spracúvané osobné údaje, ktoré sa jej týkajú.</p>

<h4 className="tbk__icon fs-xl tcolor">Zásady ochrany osobných údajov</h4>

<p>Vaše osobné údaje budú uchovávané bezpečne, v súlade s bezpečnostnou politikou prevádzkovateľa a len po dobu nevyhnutnú na splnenie účelu spracúvania. Prístup k Vašim osobným údajom budú mať výlučne osoby poverené prevádzkovateľom na spracúvanie osobných údajov, ktoré ich spracúvajú na základe pokynov prevádzkovateľa, v súlade s bezpečnostnou politikou prevádzkovateľa. Vaše osobné údaje budú zálohované, v súlade s retenčnými pravidlami prevádzkovateľa. Zo zálohových úložísk budú Vaše osobné údaje úplne vymazané hneď, ako v súlade s pravidlami zálohovania uvedené bude možné. Osobné údaje uchovávané na záložných úložiskách slúžia na predchádzanie bezpečnostným incidentom, najmä narušenia dostupnosti údajov v dôsledku bezpečnostného incidentu. Prevádzkovateľ je povinný zabezpečovať zálohovanie údajov v súlade s bezpečnostnými požiadavkami GDPR a zákona č. 18/2018 Z. z.</p>

<h4 className="tbk__icon fs-xl tcolor">Právny základ spracúvania Vašich osobných:</h4>

<p>Vaše osobné údaje sú spracúvané na účely vedenia registrácie a na plnenie iných nevyhnutných činností súvisiacich s činnosťou občianskeho združenia a účelov, ktoré sú stanovené prevádzkovateľom. Tieto sú jednotlivo uvedené v Informáciách o účeloch spracúvania.</p>

<h4 className="tbk__icon fs-xl tcolor">Poskytnutie Vašich údajov mimo Európsku úniu:</h4>

<p>Prenos osobných údajov do tretej krajiny alebo medzinárodnej organizácie sa neuskutočňuje. </p>

<h4 className="tbk__icon fs-xl tcolor">Použitie Vašich údajov použité na automatizované individuálne rozhodovanie:</h4>

<p>Osobné údaje nebudú použité na automatizované individuálne rozhodovanie vrátane profilovania.</p>

<h4 className="tbk__icon fs-xl tcolor">Doba uchovania Vašich osobných údajov:</h4>

<p>Na uchovávanie osobných údajov, ktoré o Vás spracúvame sa vzťahuje zákon č. 395/2002 Z. z. o archívoch a registratúrach v spojení s Registratúrnym plánom Prevádzkovateľa.</p>

<p>Pre podrobnejšie informácie o účeloch spracúvania vašich osobných údajov, právnych základoch a dobe uchovávania prosím kontaktujte poverenú osobu.</p>

<h4 className="tbk__icon fs-xl tcolor">Vaše práva</h4>

<h5 className="tbk__icon fs-s tcolor">Odvolať súhlas</h5>

<p>v prípadoch, kedy Vaše osobné údaje spracúvame na základe Vášho súhlasu, máte právo tento súhlas kedykoľvek odvolať. Súhlas môžete odvolať elektronicky, na adrese zodpovednej osoby, písomne, oznámením o odvolaní súhlasu alebo osobne v úrade. Odvolanie súhlasu nemá vplyv na zákonnosť spracúvania osobných údajov, ktoré sme na jeho základe o Vás spracúvali. </p>

<h5 className="tbk__icon fs-s tcolor">Právo na prístup</h5>

<p>máte právo na poskytnutie kópie osobných údajov, ktoré o Vás máme k dispozícii, ako aj na informácie o tom, ako Vaše osobné údaje používame. Vo väčšine prípadov Vám budú Vaše osobné údaje poskytnuté v písomnej listinnej forme, pokiaľ nepožadujete iný spôsob ich poskytnutia. Ak ste o poskytnutie týchto informácií požiadali elektronickými prostriedkami, budú Vám poskytnuté elektronicky, ak to bude technicky možné.</p>

<h5 className="tbk__icon fs-s tcolor">Právo na opravu</h5>

<p>prijímame primerané opatrenia, aby sme zabezpečili presnosť, úplnosť a aktuálnosť informácií, ktoré o Vás máme k dispozícii. Ak si myslíte, že údaje, ktorými disponujeme sú nepresné, neúplné alebo neaktuálne, prosím, neváhajte nás požiadať, aby sme tieto informácie upravili, aktualizovali alebo doplnili.  </p>

<h5 className="tbk__icon fs-s tcolor">Právo na vymazanie</h5>

<p>máte právo nás požiadať o vymazanie Vašich osobných údajov, napríklad v prípade, ak osobné údaje, ktoré sme o Vás získali, už viac nie sú potrebné na naplnenie pôvodného účelu spracúvania. Vaše právo je však potrebné posúdiť z pohľadu všetkých relevantných okolností. Napríklad, môžeme mať určité právne a regulačné povinnosti, čo znamená, že nebudeme môcť Vašej žiadosti vyhovieť.</p>

<h5 className="tbk__icon fs-s tcolor">Právo na obmedzenie spracúvania</h5>

<p>za určitých okolností ste oprávnený nás požiadať, aby sme prestali používať Vaše osobné údaje. Ide napríklad o prípady, keď si myslíte, že osobné údaje, ktoré o Vás máme, môžu byť nepresné alebo keď si myslíte, že už Vaše osobné údaje nepotrebujeme využívať.</p>

<h5 className="tbk__icon fs-s tcolor">Právo na prenosnosť údajov</h5>

<p>za určitých okolností máte právo požiadať nás, o prenos osobných údajov, ktoré ste nám poskytli, na inú tretiu stranu podľa Vášho výberu. Právo na prenosnosť sa však týka len osobných údajov, ktoré sme od Vás získali na základe súhlasu alebo na základe zmluvy, ktorej ste jednou zo zmluvných strán.</p>

<h5 className="tbk__icon fs-s tcolor">Právo  namietať</h5>

<p>máte právo namietať voči spracúvaniu údajov, ktoré je založené na našich legitímnych oprávnených záujmoch. V prípade, ak nemáme presvedčivý legitímny oprávnený dôvod na spracúvanie a Vy podáte námietku, nebudeme Vaše osobné údaje ďalej spracúvať.</p>

<h5 className="tbk__icon fs-s tcolor">Právo podať návrh na začatie konania o ochrane osobných údajov</h5>

<p>ak sa domnievate, že Vaše osobné údaje spracúvane nespravodlivo alebo nezákonne, môžete podať sťažnosť na dozorný orgán, ktorým je Úrad na ochranu osobných údajov Slovenskej republiky, Hraničná 12, 820 07 Bratislava 27; tel. číslo: +421 /2/ 3231 3214; mail: statny.dozor@pdp.gov.sk, https://dataprotection.gov.sk. V prípade podania návrhu elektronickou formou je potrebné, aby spĺňal náležitosti podľa § 19 ods. 1 zákona č. 71/1967 Zb. o správnom konaní (správny poriadok).</p>


                    {/* 
                    <div className="tbk__symbol">
                        <span className="tbk__icon fs-xxl tcolor fas fa-ellipsis-h" />
                    </div> 
                    */}
                    {/* 
                    <h4 className="tbk__subtitle fs-m tcolor">
                        WE'RE GOOD AT SOME STUFF AND VERY GOOD AT OTHER STUFF
                    </h4> 
                    */}

                    </div>
                </div>
                </div>

                {/*
                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    {t('program.pc6')}
                    </h3>
                    <p>{t('program.pc7')}</p>
                    </div>
                </div>
                </div>

                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    {t('program.pc8')}
                    </h3>
                    <p>{t('program.pc9')}</p>
                    
                    <p>{t('program.pc10')}</p>
                    </div>
                </div>
                </div>
                 */}

            </div>
            </section>





            
            <PageFooter />
        </div>
    )
}

export default Gdpr


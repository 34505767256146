import React from "react"
import PageHeader from "../components/Header"
import PageFooter from "../components/Footer"
import { useTranslation } from 'react-i18next'
import HarmonogramComponent from "../components/HarmonogramComponent"
// import AdultsComponent from "../components/AdultsComponent"



const WomenEmpowered = () => {
    const {t} = useTranslation('translation')


    return (
        <div>
            <PageHeader />


            <div id="page_header" className="page-subheader site-subheader-cst">
                <div className="bgback" />
                <div className="th-sparkles" />
                <div className="kl-bg-source">
                    <div className="kl-bg-source__bgimage" style={{backgroundImage: 'url(images/general-bg-3.jpg)', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPositionX: 'center', backgroundPositionY: 'center', backgroundSize: 'cover'}} />
                    <div className="kl-bg-source__overlay" style={{backgroundColor: 'rgba(53,53,53,0.1)'}} />
                </div>


                <div className="ph-content-wrap d-flex">
                <div className="container align-self-center">
                    <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="clearfix" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6" style={{backgroundColor: 'rgba(0,0,0,0.5)', padding: 15,}}>
                        <div className="subheader-titles">
                        <h2 className="subheader-maintitle">Women Empowered<sup>®</sup></h2>
                        <h4 className="subheader-subtitle">{t('page.womenempowered')}</h4>
                        </div>
                    </div>
                    </div>
                </div>
                </div>


                {/* <div className="kl-bottommask kl-bottommask--mask3">
                
                <svg width="2700px" height="57px" className="svgmask" viewBox="0 0 2700 57" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-mask3">
                    <feOffset dx={0} dy={3} in="SourceAlpha" result="shadowOffsetInner1" />
                    <feGaussianBlur stdDeviation={2} in="shadowOffsetInner1" result="shadowBlurInner1" />
                    <feComposite in="shadowBlurInner1" in2="SourceAlpha" operator="arithmetic" k2={-1} k3={1} result="shadowInnerInner1" />
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0" in="shadowInnerInner1" type="matrix" result="shadowMatrixInner1" />
                    <feMerge>
                        <feMergeNode in="SourceGraphic" />
                        <feMergeNode in="shadowMatrixInner1" />
                    </feMerge>
                    </filter>
                </defs>
                <path d="M-2,57 L-2,34.007 L1268,34.007 L1284,34.007 C1284,34.007 1291.89,33.258 1298,31.024 C1304.11,28.79 1329,11 1329,11 L1342,2 C1342,2 1345.121,-0.038 1350,-1.64313008e-14 C1355.267,-0.03 1358,2 1358,2 L1371,11 C1371,11 1395.89,28.79 1402,31.024 C1408.11,33.258 1416,34.007 1416,34.007 L1432,34.007 L2702,34.007 L2702,57 L1350,57 L-2,57 Z" className="bmask-bgfill" filter="url(#filter-mask3)" fill="#fbfbfb" />
                </svg>

              <i className="fas fa-angle-down" />
            </div> */}
            
            </div>


            <section className="hg_section hg_section--relative">
                {/*** <div className="kl-bg-source kl-bgSource-imageParallax js-KyHtmlParallax is-fixed is-visible" style={{height: 625, width: 1231, transform: 'translate3d(0px, 129.898px, 0px)'}}>
                    <div className="kl-bg-source__bgimage" style={{backgroundImage: 'url("images/photo-1431578500526-4d9613015464-resized.jpg")', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPosition: '50% 50%', backgroundSize: 'cover', transform: 'translate3d(0px, -100px, 0px)'}} />
                </div>
                 ***/}
                <div className="container">
                    <div className="row">

                        <div className="col-sm-12 col-md-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="left-side">
                                        <img src="/images/we-logo.png" alt="Women Empowered® Self-defense program for women" />
                                        <br /><br /><br />

                                        {/* <h3 className="kl-font-alt fs-larger tcolor fw-bold title">                                             */}
                                            {/* <span className="fw-thin"> */}
                                            {/* <span className="fw-semibold tcolor"></span> */}
                                            {/* </span> */}
                                        {/* </h3> */}
                                        {/* <p>{t('program.mc1')}</p> */}

                                        {/* <p>{t('program.mc2')}</p> */}
                                        {/*** 
                                        <ul className="features">
                                            <li><p>BJJ je dizajnovane pre sebeobranu aj proti vacsiemu a silnejsiemu oponentovi.</p></li>
                                            <li><p>Nieje potreba ziadnich predchazajucich skusenosti s bojovim umenim</p></li>
                                        </ul>
                                        <a href="#" target="_blank" className="btn btn-fullcolor btn-third">BUY NOW</a> 
                                        ***/}
                                        <p><strong>Women Empowered</strong> je oficiálny Gracie sebaobranný program pre ženy, založený na technikách brazílskeho jiu-jitsu (BJJ). V tomto 15-lekčnom programe vás naučíme, ako neutralizovať 20 najčastejších útokov, od chytania za vlasy až po situáciu, keď vás útočník s zbraňou pripúta k zemi. Každá hodinová lekcia Women Empowered zahŕňa dve techniky. Program môžete začať kedykoľvek a zúčastniť sa akejkoľvek hodiny bez predchádzajúcich skúseností. Na rozdiel od väčšiny systémov sebaobrany, ktoré sa spoliehajú na silu, rýchlosť a koordináciu, techniky v programe Women Empowered využívajú pákový efekt, techniku a načasovanie, takže ich môže zvládnuť ktokoľvek bez ohľadu na vek alebo fyzické schopnosti. </p>                                        
                                    </div>
                                </div>
                                
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                        <iframe title="mcp1" src="https://www.youtube.com/embed/pndPbpHLpos?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>




            <section id="services" className="hg_section pt-10 pb-0">
            <div className="container">

                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    {/* {t('program.mc3')} */}
                    20 kľúčových techník
                    </h3>
                    {/* 
                    <div className="tbk__symbol">
                        <span className="tbk__icon fs-xxl light-tcolor fas fa-ellipsis-h" />
                    </div> 
                    */}
                    {/* 
                    <h4 className="tbk__subtitle fs-m light-tcolor">
                        WE'RE GOOD AT SOME STUFF AND VERY GOOD AT OTHER STUFF
                    </h4> 
                    */}
                    {/* <p>{t('program.mc4')}</p> */}
                    <p>Orgány činné v trestnom konaní a obete sexuálneho násilia pomohli identifikovať 20 najčastejších stratégií útokov, ktoré môže mužský útočník použiť proti žene. Program Women Empowered pokrýva všetko – od chytania za vlasy, škrtenia až po stiahnutie obete na zem.</p>
                    </div>
                </div>
                </div>

        {/* <section id="services" className="hg_section pt-60 pb-100"> */}
            <div className="container">

                <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="services_box services_box--boxed sb--hasicon">	
                    <div className="services_box__inner clearfix">
                        {/* <div className="services_box__icon">
                        <div className="services_box__icon-inner">
                            <span className="services_box__fonticon icon-noun_65754" />
                        </div>
                        </div> */}
                        <div className="services_box__content">
                        <h4 className="services_box__title">
                        {t('step')} 1: <br />Naučte sa techniky
                        </h4>
                        <div className="services_box__desc">
                            <p>
                            20 techník Women Empowered sme rozdelili do 15 hodinových lekcií. Každá lekcia je navrhnutá tak, aby ste mohli začať program kedykoľvek a dokončiť 15 lekcií v akomkoľvek poradí. Vystavíme Vám kartu Women Empowered, na ktorej budeme sledovať váš progres na každom kroku.
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="services_box services_box--boxed sb--hasicon">	
                    <div className="services_box__inner clearfix">
                        {/* <div className="services_box__icon">
                        <div className="services_box__icon-inner">
                            <span className="services_box__fonticon icon-process2" />
                        </div>
                        </div> */}
                        <div className="services_box__content">
                        <h4 className="services_box__title">
                        {t('step')} 2: <br />Rozvíjajte reflexy
                        </h4>
                        <div className="services_box__desc">
                            <p>
                            Po absolvovaní každej z 15 lekcií Women Empowered aspoň raz budete môcť pokračovať v Reflex Development (RD) hodinách, kde sa naučíte, ako kombinovať 20 techník na riešenie najčastejších situácií v reálnej sebaobrane.
                            </p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="services_box services_box--boxed sb--hasicon">	
                    <div className="services_box__inner clearfix">
                        {/* <div className="services_box__icon">
                        <div className="services_box__icon-inner">
                            <span className="services_box__fonticon icon-process3" />
                        </div>
                        </div> */}
                        <div className="services_box__content">
                        <h4 className="services_box__title">
                        {t('step')} 3: <br />Získajte ružový pás
                        </h4>
                        <div className="services_box__desc">
                            <p>
                            Po absolvovaní každej lekcie Women Empowered štyrikrát a najmenej 8 Reflex Development hodín môžete absolvovať test na získanie ružového pásu! Počas 30-minútového testu zhodnotíme vaše vykonanie všetkých 20 kľúčových techník. Ak váš výkon splní štandardy Gracie University, kvalifikujete sa na ružový opasok. Ružový opasok symbolizuje nielen odhodlanie zvládnuť techniky programu, ale aj pochopenie filozofie prežitia v života ohrozujúcich situáciách.
                            </p>
                        </div>
                        {/* <div className="services_box__list-wrapper">
                            <span className="services_box__list-bg" />
                            <ul className="services_box__list" style={{paddingTop: 250}}>
                            <li><span className="services_box__list-text">Search engine optimisation</span></li>
                            <li><span className="services_box__list-text">Search engine marketing</span></li>
                            <li><span className="services_box__list-text">Internet advertising</span></li>
                            <li><span className="services_box__list-text">Web analysis</span></li>
                            <li><span className="services_box__list-text">Blog marketing</span></li>
                            </ul>
                        </div> */}
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* </section> */}


                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    Čo je ružový pás?
                    </h3>
                    <p>Ružový pás predstavuje nový prídavok k tradičnému systému pásov BJJ, špeciálne prispôsobený filozofii a technikám Women Empowered. Tento pás nie je len symbolom technickej zdatnosti, ale aj posilnenej sebadôvery a schopnosti vyhnúť sa ohrozeniu.</p>
                    </div>
                </div>
                </div>

                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    Bezplatný online prístup a tréningové výhody
                    </h3>
                    <p>Pri zápise do programu Women Empowered získate bezplatné predplatné na platformu Gracie University Online. Navyše môžete bezplatne trénovať až 30 po sebe idúcich dní ročne na ktoromkoľvek certifikovanom tréningovom centre Gracie po celom svete.</p>
                    </div>
                </div>
                </div>

                <div className="row mb-30">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    10-dňová bezplatná skúška
                    </h3>
                    <p>Využite 10-dňovú bezplatnú skúšku, ktorá zahŕňa neobmedzený prístup ku všetkým skupinovým hodinám pre začiatočníkov. Ak nesplníme vaše očakávania, za absolvované hodiny vám nebudeme účtovať nič. Kontaktujte najbližšie certifikované tréningové centrum a naplánujte si prvú hodinu ešte dnes!</p>
                    </div>
                </div>
                </div>


            </div>
            </section>

            {/* <AdultsComponent /> */}
            <HarmonogramComponent category={["we"]} />


            <section id="services" className="hg_section">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12 col-md-12">
                        <div className="screenshot-box">
                            <div className="row">
    
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                    <iframe title="mcp2" src="https://www.youtube.com/embed/q2K051yL1bY?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                    <iframe title="mcp3" src="https://www.youtube.com/embed/-FUMcJltEXQ?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                </div>
                            </div>    
                            <div className="row">

                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                    <iframe title="mcp4" src="https://www.youtube.com/embed/Gyr9gOtRdJ8?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                    <iframe title="mcp5" src="https://www.youtube.com/embed/e7NpXQkw5pU?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
            </section>










            
            <PageFooter />
        </div>
    )
}

export default WomenEmpowered


import React from "react"
import '../PageSlider.css'
import { NavLink } from "react-router-dom"
import { useTranslation } from 'react-i18next'


const PageSlider = () => {
  const {t} = useTranslation('translation')


  return (

    // <NavLink to="/gracie-combatives">Gracie Combatives<sup>®</sup> <br /><strong>Zaciatocnicky program</strong></NavLink>
    // <NavLink to="/gracie-bullyproof">Gracie Bullyproof<sup>®</sup> <br /><strong>Program pre deti</strong></NavLink>
    // <NavLink to="/gracie-master-cycle">Master Cycle<sup>®</sup> <br /><strong>Program pre pokrocilich</strong></NavLink>
    // <NavLink to="/individualne-treningy"><strong>Individualne treningy</strong></NavLink>



<div className="kl-slideshow kl-slideshow-css3panels">
    <div className="fake-loading loading-1s">
    </div>
    <div className="css3panels-container css3panels--resize cssp-capt-animated cssp-capt-default" style={{height: 700}}>
      <div className="css3panel css3panel--1 cp-theme--light">
        <div className="css3panel-inner" style={{height: 700}}>
          <div className="css3panel-mainimage-wrapper" style={{height: 700}}>
            <div className="css3panel-mainimage css3panel-mainimage-s1">
              <div className="css3p-overlay css3p--overlay-color" style={{background: 'rgba(13,163,190,0.3)'}} />
            </div>
          </div>
        </div>
        <div className="css3panel-caption" style={{background: "rgba(0,0,0,0.5)"}}>
            <h3 className="css3panel-title" style={{margin: "20px"}}>
              {/* <small>{t('page.beginers')}</small> */}
              <NavLink to={t('url.gracie-combatives')} className="white"><small>Gracie Combatives<sup>®</sup> <br /><strong>{t('page.beginers')}</strong></small></NavLink>
            </h3>
          {/* <div className="css3panel-btn-area"> */}
            {/* <NavLink to={t('url.gracie-combatives')} className="btn btn-lined btn-skewed"> */}
            {/* <NavLink to="/dospelaci" className="btn btn-lined btn-skewed"> */}
            {/* {t('showmore')} */}
            
            {/* </NavLink> */}
          {/* </div> */}
        </div>
      </div>
      <div className="css3panel css3panel--2 cp-theme--light">
        <div className="css3panel-inner" style={{height: 700}}>
          <div className="css3panel-mainimage-wrapper" style={{height: 700}}>
            <div className="css3panel-mainimage css3panel-mainimage-s2">
              <div className="css3p-overlay css3p--overlay-color" style={{background: 'rgba(188,13,118,0.3)'}} />
            </div>
          </div>
        </div>
        <div className="css3panel-caption" style={{background: "rgba(0,0,0,0.5)"}}>
            <h3 className="css3panel-title" style={{margin: "20px"}}>
            {/* <NavLink to="/deti" className="white"><small>{t('page.kids')}</small></NavLink> */}
            <NavLink to={t('url.gracie-bullyproof')} className="white"><small>Gracie Bullyproof<sup>®</sup> <br /><strong>{t('page.kids')}</strong></small></NavLink>
            </h3>
            {/* <div className="css3panel-btn-area">
            <NavLink to="/deti" className="btn btn-lined btn-skewed">
            {t('showmore')}
            </NavLink>
          </div>*/}
        </div>
      </div>

      <div className="css3panel css3panel--5 cp-theme--light">
        <div className="css3panel-inner" style={{height: 700}}>
          <div className="css3panel-mainimage-wrapper" style={{height: 700}}>
            <div className="css3panel-mainimage css3panel-mainimage-s5">
              <div className="css3p-overlay css3p--overlay-color" style={{background: 'rgba(8,25,119,0.3)'}} />
            </div>
          </div>
        </div>
        <div className="css3panel-caption" style={{background: "rgba(0,0,0,0.5)"}}>
            <h3 className="css3panel-title" style={{margin: "20px"}}>
              <NavLink to={t('url.gracie-women-empowered')} className="white"><small>Women Empowered<sup>®</sup> <br /><strong>{t('page.womenempowered')}</strong></small></NavLink>
            </h3>
        </div>
      </div>


      <div className="css3panel css3panel--3 cp-theme--light">
        <div className="css3panel-inner" style={{height: 700}}>
          <div className="css3panel-mainimage-wrapper" style={{height: 700}}>
            <div className="css3panel-mainimage css3panel-mainimage-s3">
              <div className="css3p-overlay css3p--overlay-color" style={{background: 'rgba(1,178,25,0.3)'}} />
            </div>
          </div>
        </div>
        <div className="css3panel-caption" style={{background: "rgba(0,0,0,0.5)"}}>
            <h3 className="css3panel-title" style={{margin: "20px"}}>
            {/* <small>{t('page.advanced')}</small> */}
            <NavLink to={t('gracie-master-cycle')} className="white"><small>Gracie Master Cycle<sup>®</sup> <br /><strong>{t('page.advanced')}</strong></small></NavLink>
            </h3>
        </div>
      </div>
      

      {/* <div className="css3panel css3panel--4 cp-theme--light">
        <div className="css3panel-inner" style={{height: 700}}>
          <div className="css3panel-mainimage-wrapper" style={{height: 700}}>
            <div className="css3panel-mainimage css3panel-mainimage-s4">
              <div className="css3p-overlay css3p--overlay-color" style={{background: 'rgba(8,25,119,0.3)'}} />
            </div>
          </div>
        </div>
        <div className="css3panel-caption" style={{background: "rgba(0,0,0,0.5)"}}>
            <h3 className="css3panel-title" style={{margin: "20px"}}>
              <NavLink to={t('url.private-class')} className="white"><small>Private lessons <br /><strong>{t('page.private-class')}</strong></small></NavLink>
            </h3>
        </div>
      </div> */}

    </div>
    <div className="clearfix">
    </div>
    <div className="kl-bottommask kl-bottommask--shadow_ud">
    </div>
  </div>



  )
}

export default PageSlider
